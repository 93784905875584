import { css } from 'styled-components'

export const gray = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#D1D4E3',
  400: '#F5F6FA',
  500: '#E6E7F0',
  600: '#8C91A9',
  700: '#6A6E81',
  800: '#4B4E5C',
  900: '#D7D7D7',
  light: '#747684',
  base: '#58595b',
  medium: '#4B4E5C',
  dark: '#292929',
}

export const grayscale = {
  100: '#F5F6FA',
  200: '#DEDFE4',
  300: '#B6B7BB',
  400: '#6A6C72',
  500: '#161616',
}

export const white = '#FFFFFF'

export const orange = {
  100: '#FFF2E7',
  light: '#FF9F33',
  base: '#FF7A00',
  dark: '#FF500F',
}

export const brown = {
  base: '#A56543',
}

export const green = {
  base: '#8DC63F',
  light: '#a4d266',
  dark: '#20C05D',
  left: '#DEE021',
  right: '#A4D264',
  extraDark: '#027A75',
  500: '#07605b',
}

export const yellow = {
  500: '#FFBA00',
}

export const red = {
  base: '#FD4F47',
}

export const blue = {
  500: '#194A69',
  700: '#072534',
  900: '#161616',
}

export const linear = {
  orange: 'linear-gradient(45deg, #FF500F 0%, #FF8700 100%)',
}

type TColor = {
  [name: string]: string;
}

export function generateColorClasses (colorMap: TColor, name: string) {
  return (
    Object.keys(colorMap).map((type: string) => (
      css`
        .bg-${name}--${type} { background: ${colorMap[type]}; }
        .text-${name}--${type}   { color: ${colorMap[type]}; }
        .fill-${name}--${type}   { fill: ${colorMap[type]}; }
      `
    ))
  )
}
