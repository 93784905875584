import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const Link = styled.a`
  border: none;
  bottom: 0;
  border-radius: 17px 17px 0 0;
  bottom: 0;
  padding: 5px 0 2px 0;
  position: fixed;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 250px;
  z-index: 1;
  background-color: #8DC63F;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  -o-transition: -o-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;

  &:hover {
    background: #80b636;
    color: #fff;
  }

  &.open-banner {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0) translateX(-50%);
  }

  &.close-banner {
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    -o-transform: translateY(70px);
    transform: translateY(70px) translateX(-50%);

    button {
      &.close {
        display: none;
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    right: 50px;
  }

  @media (min-width: ${breakpoints.md}) and (max-width: 1024px) and (orientation: landscape) {
    right: 55px;
  }
`
