import { createGlobalStyle } from 'styled-components'
import normalize from './normalize'
import breakpoints from './breakpoints'
import grid from './grid'
import { white, gray, grayscale, orange, red, generateColorClasses, green } from './colors'
import { generateFontSizeClasses, generateFontWeightClasses, generateLineHeightClasses } from './fonts'
import { generateBorderRadiusClasses } from './radius'

import 'react-multi-carousel/lib/styles.css'
import '../sass/bootstrap.scss'

const globalStyle = createGlobalStyle`
  ${normalize}
  ${grid}

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Sora';
  }

  .list-inline {
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;
  }

  address {
    font-style: normal;

    .fs-footer-10 {
      margin-left: 4px;
      width: 100%;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${orange.base};

    &:hover {
      color: ${grayscale['400']}
    }
  }

  .fill-white {
    fill: ${white};
  }

  .h-breadcrumb {
    @media(min-width: ${breakpoints.lg}) {
      min-height: 60px;
    }
  }

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateColorClasses(red, 'red')}
  ${generateColorClasses(green, 'green')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
`

export default globalStyle
