import React, { ReactNode } from 'react'

import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import { ModalArea } from 'src/components/Modal'
import OpenAccountLink from 'src/components/OpenAccountLink'
import CDP from 'src/components/cdp'

import GlobalStyle from 'src/styles/global'

type Layout = {
  children: ReactNode;
  category?: string | undefined;
}

function Layout ({ children, category }: Layout) {
  return (
    <>
      <GlobalStyle />
      <ModalArea />
      <NewHeader
        theme={{
          name: 'default',
        }}
      />
      <main>{children}</main>
      <Footer />
      <OpenAccountLink articleCategory={category} />
      <CDP />
    </>
  )
}

export default Layout
