import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 900;
  top: 0;
`

export const ModalContainer = styled.div<{ width: string }>`
  position: relative;
  z-index: 100;
  width: ${props => props.width || 'auto'};
  min-width: 300px;
  max-width: 95vw;
  max-height: 90vh;
  border-radius: 8px;
  box-shadow: 0px 8px 10px rgba(0,0,0,.3);

  @media(min-width: ${breakpoints.md}) {
    max-height: 82vh;
  }
`

export const ModalInnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
  border-radius: 6px;
  background: white;

  @media(min-width: ${breakpoints.md}) {
    max-height: 82vh;
  }
`

export const ModalBody = styled.div<{ noPadding: boolean }>`
  padding: ${props => props.noPadding ? '0' : '16px'};
`

export const ModalCloseButton = styled.button`
  position: absolute;
  right: -10px;
  top: -10px;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: white;
  box-shadow: 0 3px 5px rgba(0,0,0,.3);
  text-align: center;
  padding: 0;
  line-height: 32px;
  z-index: 901;

  &:active {
    outline: none;
  }
`
