type TBreakpoints = {
  [name: string]: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

const breakpoints: TBreakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export default breakpoints
