/** Convert account number */
export const interAccountMask = (value, evt) => {
  value = value.replace(/-/gi, '')
  if (value.length > 5) {
    return value.replace(/(\d{1}$)/, '-$1')
  } else return value
}

/**
 * Debounce
 */
export const debounce = (func, wait, immediate) => {
  let timeout
  return () => {
    const context = this
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context)
  }
}

/**
 * Throttle
 */
export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}
