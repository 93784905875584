import React from 'react'
import { Helmet } from 'react-helmet'

type Meta = {
  name?: string;
  property?: string;
  content?: string;
}

interface ISeo {
  title: string;
  meta?: Meta[];
  url: string | undefined;
  noIndex?: boolean;
}

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Inter',
  alternateName: 'Blog do Inter',
  url: 'https://www.blog.inter.co',
  logo: 'https://marketing.bancointer.com.br/comunicacao/e-mail-mkt-institucional/imagens/banco-inter.png',
  sameAs: [
    'https://www.inter.co',
    'https://www.gointer.com',
    'https://www.facebook.com/bancointer',
    'https://twitter.com/Bancointer',
    'https://www.instagram.com/bancointer/',
    'https://www.youtube.com/c/BancoInter',
    'https://www.linkedin.com/company/banco-inter/mycompany/',
  ],
})

const searchSchema = JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'Blog do Inter',
  url: 'https://blog.inter.co',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://blog.inter.co/search?q={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
})

const tagManagerScript = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
  '    new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
  '    j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
  '    \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
  '    })(window,document,\'script\',\'dataLayer\',\'GTM-WFMLG3S\');'

const SEO = ({ title, url, meta, noIndex }: ISeo) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pt-BR',
        }}
        title={title}
        titleTemplate={title}
        meta={meta}
      >
        <script type='application/ld+json'>
          {organizationSchema}
        </script>
        <script type='application/ld+json'>
          {searchSchema}
        </script>
        <script type='text/javascript'>
          {tagManagerScript}
        </script>
        <link rel='canonical' href={url} />
        {noIndex && <meta name='robots' content='noindex' />}
      </Helmet>
      <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-WFMLG3S'
          height='0'
          width='0'
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    </>
  )
}

SEO.defaultProps = {
  meta: [],
}

export default SEO
