import { css } from 'styled-components'

export const borderRounded = {
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  5: '5px',
  8: '8px',
  10: '10px',
  12: '12px',
  16: '16px',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
}

export function generateBorderRadiusClasses () {
  return (
    Object.keys(borderRounded).map((radius: string) => (
      css`
        .rounded-${radius} { border-radius: ${radius}px !important; }
      `
    ))
  )
}
