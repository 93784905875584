import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import useDomReady from 'src/hooks/useDomReady'

import {
  ModalOverlay,
  ModalContainer,
  ModalInnerContainer,
  ModalBody,
  ModalCloseButton,
} from 'src/components/Modal/style'

import Icon from 'src/components/Icon'

const modalAreaId = 'modal-area'

export const ModalArea = React.memo(() => (<div id={modalAreaId} />))

type ModalProps = {
  children: React.ReactNode;
  showCloseButton: boolean;
  onClose: () => void;
  onClickOutside: () => void;
  width?: string;
  noPadding: boolean;
}
const Modal = ({ children, width, showCloseButton, onClose, onClickOutside, noPadding }: ModalProps) => {
  const domReady = useDomReady()

  const portalNode = useMemo(() => {
    return domReady ? document.getElementById(modalAreaId) : null
  }, [ domReady ])

  const handleClickOutside = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) { onClickOutside() }
  }

  const modalContent = (
    <ModalOverlay onClick={handleClickOutside}>
      <ModalContainer width={width}>
        {showCloseButton && (
          <div>
            <ModalCloseButton type='button' onClick={onClose}>
              <Icon width={10} height={10} directory='v2' icon='navigation/close' color='black' />
            </ModalCloseButton>
          </div>
        )}
        <ModalInnerContainer>
          <ModalBody noPadding={noPadding}>
            {children}
          </ModalBody>
        </ModalInnerContainer>
      </ModalContainer>
    </ModalOverlay>
  )

  return domReady ? ReactDOM.createPortal(modalContent, portalNode) : null
}

Modal.defaultProps = {
  width: '500px',
  showCloseButton: true,
  noPadding: false,
}

export default Modal
